<template>
  <b-card no-body class="mb-0  mt-2">
    <b-overlay :show="loading" rounded="sm" no-fade>
      <div class="m-2">
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="9" v-if="$Can('files_add_btn')">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="filter" class="d-inline-block mr-1" cle placeholder="Search..." />
              <b-button variant="primary" class="text-nowrap" @click="addNewFile()">
                <span>Add File</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        striped
        hover
        class="position-relative"
        :items="listFiles"
        :filter="filter"
        filter-debounce="250"
        :filter-included-fields="filterOn"
        selectable
        select-mode="single"
        responsive
        :small="true"
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        :current-page="currentPage"
        :per-page="perPage"
      >
        <!-- @row-selected="quotePreview" -->
        <!-- Column: id -->
        <template #cell(id)="data">
          <strong class="text-primary">#{{ Number(data.item.id ? data.item.id : 0) }}</strong>
        </template>
        <!-- Column: file name -->
        <template #cell(fileName)="data"> {{ data.item.fileName ? data.item.fileName : 'N/A' }} </template>

        <!-- Column: User -->
        <template #cell(user)="data">
          <span v-if="data.item.userId != -1">{{ data.item.user ? data.item.user.name + ' ' + data.item.user.surname : 'N/A' }}</span>
          <span v-else>All Users</span>
        </template>

        <!-- Column: User -->
        <template #cell(password)="data">
          <span v-if="data.item.password != null && data.item.password != ''">
            <b-badge variant="light-success">
              True
            </b-badge>
          </span>
          <span v-else>
            <b-badge variant="light-danger">
              False
            </b-badge>
          </span>
        </template>

        <template #cell(internal)="data">
          <span v-if="data.item.internalFile">
            <b-badge variant="light-success">
              True
            </b-badge>
          </span>
          <span v-else>
            <b-badge variant="light-danger">
              False
            </b-badge>
          </span>
        </template>


        <!-- Column: created_at -->
        <template #cell(createDate)="data"> {{ data.item.created_at ? dateFormat(data.item.created_at) : 'N/A' }} </template>

        <!-- Column: updated_at -->
        <template #cell(updateDate)="data"> {{ data.item.updated_at ? dateFormat(data.item.updated_at) : 'N/A' }} </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- @click="quoteDownload(data.item.fileLink)" -->
          <b-button  v-if="$Can('files_download_btn')" variant="primary" target="_blank" :href="excelFileURL + data.item.fileLink" class="btn-icon" size="sm">
            <feather-icon icon="ArrowDownIcon" size="16" style="color:white" />
          </b-button>

          <b-button  v-if="$Can('files_add_btn')" variant="warning" target="_blank" @click="fileEdit(data.item)" class="btn-icon  " style="margin-left:2px" size="sm">
            <feather-icon icon="AlignCenterIcon" size="16" style="color:white" />
          </b-button>

          <b-button  v-if="$Can('files_replace_btn')" variant="info" target="_blank" @click="fileUpload(data.item)" class="btn-icon  " style="margin-left:2px" size="sm">
            <feather-icon icon="ArrowUpIcon" size="16" style="color:white" />
          </b-button>

          <b-button  v-if="$Can('files_share_btn')" variant="success" target="_blank" @click="fileShare(data.item)" class="btn-icon  " style="margin-left:2px" size="sm">
            <feather-icon icon="Share2Icon" size="16" style="color:white" />
          </b-button>

          <b-button  v-if="$Can('files_remove_btn')" variant="danger" target="_blank" @click="deleteFile(data.item)" class="btn-icon " style="margin-left:2px" size="sm">
            <feather-icon icon="XIcon" size="16" style="color:white" />
          </b-button>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>

          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <!-- Add New File -->
      <b-modal id="modal-file" cancel-variant="outline-secondary" ok-title="Save File" @ok="handleOk" @show="resetModal" @hidden="resetModal" cancel-title="Close" :no-close-on-backdrop="true" centered title="Add New File">
        <b-form ref="fileRules">
          <b-form-group :state="nameState" invalid-feedback="File Name is required">
            <label for="fileName">File Name:</label>
            <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
          </b-form-group>
          <b-form-group :state="fileState" invalid-feedback="File is required">
            <b-form-file @input="fileSelected" type="file" :state="fileState" required placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
          </b-form-group>

          <b-form-group >
            <label for="fileName">Internal File: </label>
            <b-form-checkbox v-model="modalIsInternal" name="is-vertical-menu-collapsed" class="mr-0" switch inline />
          </b-form-group>


          <b-form-group>
            <label for="password">Password</label>
            <b-form-input id="password" type="text" v-model="modalFilePassword" placeholder="Password" />
          </b-form-group>
        </b-form>
        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>
      </b-modal>

      <!-- Update File -->
      <b-modal id="modal-file-update" cancel-variant="outline-secondary" ok-title="Change File" @ok="handleUpdate" @hidden="resetModal" cancel-title="Close" :no-close-on-backdrop="true" centered title="Update File">
        <b-form ref="fileRules">
          <b-form-group :state="nameState" invalid-feedback="File Name is required">
            <label for="fileName">File Name:</label>
            <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
          </b-form-group>

          <b-form-group >
            <label for="fileName">Internal File: </label>
            <b-form-checkbox v-model="modalIsInternal" name="is-vertical-menu-collapsed" class="mr-0" switch inline />
          </b-form-group>

          <b-form-group>
            <label for="password">Password</label>
            <b-form-input id="password" type="text" v-model="modalFilePassword" placeholder="Password" />
          </b-form-group>
        </b-form>
        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>
      </b-modal>

      <!-- Update File Document -->
      <b-modal id="modal-file-upload" @hidden="resetModal" :no-close-on-backdrop="true" centered title="Upload File">
        <b-form ref="fileRules">
          <b-form-group :state="fileState" invalid-feedback="File is required">
            <b-form-file @input="fileSelected" type="file" :state="fileState" required placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
          </b-form-group>
        </b-form>
        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>

        <template #modal-footer>
          <div class="w-100">
            <div class="text-danger float-left" v-if="percentCompleted == 0">
              Warning! This process will replace the existing file with the new file.
            </div>

            <div class="text-success float-left" v-else>
              The file has been updated successfully.
            </div>
          </div>
        </template>
      </b-modal>

      <!-- File Share -->
      <b-modal id="modal-file-share" @hidden="resetModal" :no-close-on-backdrop="true" centered title="Share File">
        <strong>
          Share Link:
        </strong>

        <div class="d-flex">
          <!-- form input -->
          <b-form-group class="mb-0 mr-1 w-100">
            <b-form-input v-model="modalFileLink" />
          </b-form-group>

          <!-- button -->
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" @click="doCopy">
            Copy!
          </b-button>
        </div>

        <!-- <span> https://sharelink.tmss-repo.com/public/shareLink/{{ modalFileLink }} </span> -->

        <template #modal-footer>
          <div class="w-100">
            <div class="text-danger float-left" v-if="percentCompleted == 0">
              <!-- Warning! This process will replace the existing file with the new file. -->
            </div>

            <div class="text-success float-left" v-else>
              <!-- The file has been updated successfully. -->
            </div>
          </div>
        </template>
      </b-modal>
    </b-overlay>
  </b-card>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import filesStoreModule from '../filesStoreModule';
import vSelect from 'vue-select';
import router from '@/router';
import moment from 'moment';
import axiosIns from '@/libs/axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import pdf from 'vue-pdf';
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    pdf,
    PDFViewer,
    ToastificationContent,
  },

  directives: {
    Ripple,
  },

  setup() {
    const INVOICES_APP_STORE_MODULE_NAME = 'quotes';
    // Register module
    if (!store.hasModule(INVOICES_APP_STORE_MODULE_NAME)) store.registerModule(INVOICES_APP_STORE_MODULE_NAME, filesStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICES_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICES_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      pdfLink: null,
      listFiles: [],
      userList: [],
      passwordRequired: false,
      modalFileLink: null,
      tempUserList: [
        {
          id: -1,
          title: 'All Users',
        },
      ],

      modalFileName: null,
      modalFile: null,
      modalFilePassword: null,
      modalIsInternal : false,

      userData: JSON.parse(localStorage.getItem('userData')),
      filterOn: ['id', 'fileName', 'user', 'createDate', 'updateDate'],
      tableColumns: [
        { key: 'id', sortable: true, class: 'text-center', thStyle: 'width: 5%' },
        { key: 'fileName', sortable: true, class: 'text-center', thStyle: 'width: 30%' },
        { key: 'user', sortable: true, class: 'text-center', thStyle: 'width: 15%' },
        { key: 'password', sortable: true, class: 'text-center', thStyle: 'width: 5%' },
        { key: 'internal', sortable: true, class: 'text-center', thStyle: 'width: 5%' },
        { key: 'createDate', label: 'CREATED DATE', sortable: true, class: 'text-center', thStyle: 'width: 10%' },
        { key: 'updateDate', labe: 'UPDATED DATE', sortable: true, class: 'text-center', thStyle: 'width: 10%' },
        { key: 'actions', sortable: false, class: 'text-center', thStyle: 'width: 20%' },
      ],
      perPage: localStorage.getItem('quoteShow') ?? 10,
      currentPage: 1,
      totalRows: 0,

      perPageOptions: [10, 25, 50, 100],
      filter: null,
      loading: false,
      percentCompleted: 0,
      excelFileURL: store.state.app.excelFileURL,

      download: false,
    };
  },

  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem('quoteShow', val);
      },
    },
  },

  methods: {
    doCopy() {
      navigator.clipboard.writeText(this.modalFileLink);

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Link copied',
          icon: 'BellIcon',
        },
      });
    },

    fetchFiles() {
      this.loading = true;
      store
        .dispatch('quotes/fetchFiles', [])
        .then((res) => {
          this.listFiles = res.data;
          this.totalRows = res.data.length;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addNewFile() {
      this.$bvModal.show('modal-file');
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      if (this.percentCompleted == 100) {
        var data = {
          fileName: this.modalFileName,
          fileLink: this.modalFile,
          filePassword: this.modalFilePassword,
          internalFile:this.modalIsInternal
        };

        this.modalOk(data);
      }
    },

    handleUpdate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.modalUpdate();
    },

    modalOk(data) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-file');
      });

      this.loading = true;

      axiosIns
        .post('saveFilesFile', data)
        .then((res) => {
          this.listFiles = res.data;
          // this.totalRows = res.data.length;

          this.modalFileName = null;
          this.modalFile = null;
          this.modalFilePassword = null;
          this.modalIsInternal=false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    modalUpdate(val) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-file-update');
      });

      this.loading = true;

      var data = {
        id: this.modalId,
        fileName: this.modalFileName,
        fileLink: this.modalFile,
        filePassword: this.modalFilePassword,
        internalFile:this.modalIsInternal

      };

      axiosIns
        .put('updateFilesFile', data)
        .then((res) => {
          this.listFiles = res.data;

          this.modalFileName = null;
          this.modalFile = null;
          this.modalFilePassword = null;
          this.modalId = 0;
          this.modalIsInternal = false;

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    fileSelected(event) {
      //  this.loading = true;
      console.log(this.modalId);
      let formData = new FormData();
      formData.append('file', event);
      formData.append('fileId', this.modalId ?? 0);

      const config = {
        onUploadProgress: function(progressEvent) {
          this.percentCompleted = Math.round((progressEvent.loaded * 99) / progressEvent.total);
        }.bind(this),
      };

      axiosIns
        .post('uploadFilesFile', formData, config)
        .then((response) => {
          this.modalFile = response.data;
          this.percentCompleted = 100;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    fileEdit(data) {
      this.modalId = data.id;
      this.modalFileName = data.fileName;
      this.modalIsInternal = data.internalFile;

      this.modalFilePassword = null;
      this.$bvModal.show('modal-file-update');
    },

    fileUpload(data) {
      this.modalId = data.id;
      this.$bvModal.show('modal-file-upload');
    },

    fileShare(data) {
      console.log(data);
      this.modalId = data.id;
      this.modalFileLink = 'https://sharelink.tmss-repo.com/public/shareLink/' + data.fileLink;
      this.$bvModal.show('modal-file-share');
    },

    deleteFile(data) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;

          axiosIns
            .delete(`deleteFilesFile/${data.id}`)
            .then((res) => {
              this.listFiles = res.data;
              this.totalRows = res.data.length;

              this.loading = false;
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },

    resetModal() {
      this.tempUserList = [
        {
          id: -1,
          title: 'All Users',
        },
      ];
      this.modalFileName = null;
      this.modalFile = null;
      this.modalFilePassword = null;
      this.percentCompleted = 0;
      this.modalId = 0;
      this.modalIsInternal = false;
    },

    checkFormValidity() {
      const nameValid = this.$refs.fileRules.checkValidity();
      return nameValid;
    },

    dateFormat(val) {
      return moment(String(val)).format('DD-MM-YYYY HH:mm');
    },
  },

  created() {
    this.fetchFiles();
  },

  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },

    nameState() {
      return this.modalFileName != null && this.modalFileName != '' ? true : false;
    },

    fileState() {
      return this.modalFile != null && this.modalFile != '' ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
